import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from './../../style-utilities/variables';
import Related3Grid from './../../components/Related/Related3Grid';

const RelatedContentTechnologyAlliance = () => {
  return (
    <StaticQuery
      query={TOPICS_ALLIANCE_TECHQUERY}
      render={data => {
        /*
        NOTE: The topic ids below are hardcoded to check against the posts coming in from the query below.
        85 = Adobe
        164 = Salesforce
        165 = Oracle
        */

        //eliminate the dummy data
        const noDummy = edges =>
          edges.filter(edge => edge.node.slug !== 'do-not-delete');

        //retrieve only the results where technology is not an empty array
        const filterForTech = arr =>
          arr.filter(item => item.node.technology.length !== 0);

        const arrayMixed = [];
        const arrayAll = [];

        if (data.allWordpressPost) {
          data.allWordpressPost.edges.forEach(function(o) {
            arrayAll.push(o);
          });
        }

        // >>>>>>>>>> indexOf parameters must match appropriate pages
        const topicID =
          /* Adobe */
          window.location.href.indexOf('strategic-alliances/adobe') !== -1
            ? 85
            : /* SalesForce */
            window.location.href.indexOf('strategic-alliances/salesforce') !==
              -1
            ? 164
            : /* Oracle */
            window.location.href.indexOf('strategic-alliances/oracle') !== -1
            ? 165
            : '';

        arrayAll.sort(function(a, b) {
          return new Date(b.node.date) - new Date(a.node.date);
        });

        //Add trailing / to url if not there so url logic works consistently
        let lastChar = window.location.href.substr(-1); // Selects the last character
        if (lastChar !== '/') {
          // If the last character is not a slash
          window.location.href = window.location.href + '/'; // Append a slash to it.
        }

        //Checking if including all topics or specific topics
        let urlPath = window.location.href.split('/');
        if (urlPath[urlPath.length - 2] === 'strategic-alliances') {
          arrayMixed.push(arrayAll[0].node);
          arrayMixed.push(arrayAll[1].node);
          arrayMixed.push(arrayAll[2].node);
        } else {
          // Pushes all existing items to arrayMixed if post topic is Adobe, SalesForce, or Oracle
          for (let i in arrayAll) {
            if (arrayAll[i].node.topic.indexOf(topicID) !== -1) {
              arrayMixed.push(arrayAll[i].node);
            }
          }
        }
        return <Related3Grid header="Read These Next" items={arrayMixed} />;
      }}
    />
  );
};

//Topic#: 39
//Name: Ansira Edge
//Load all Ansira Edge topics and sort by date (newest to oldest)
const TOPICS_ALLIANCE_TECHQUERY = graphql`
  query {
    allWordpressPost(
      filter: { topic: { in: [85, 164, 165] } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          type
          topic
          slug
          date(formatString: "MMMM D, YYYY")
          technology
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }
  }
`;
export default RelatedContentTechnologyAlliance;
