import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { Link, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

//import components
import NavBar from '../components/NavBar';
import ContactBanner from '../components/ContactBanner';
import RelatedContentTechnology from '../components/Technology/RelatedContentTechnology';
import Hero from '../components/Hero';

import SEO from '../components/SEO';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import GlobalOurClients from '../components/GlobalOurClients';
import RelatedContentTechnologyAlliance from '../components/Technology/RelatedContentTechnologyAlliance';
import RelatedVideosTechnologyAlliance from '../components/Technology/RelatedVideosTechnologyAlliance';
import DefaultSliderSolutionsDynamic from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsDynamic";
import Related3Grid from '../components/Related/Related3Grid';
import ButtonGrey from '../components/ButtonGrey';

const SingleTechnologyPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  overflow-x: hidden;
  .page-container {
    max-width: 1500px;
    margin: 0 auto;
  }
  h1 {
    font-size: 48px;
    margin-bottom: 40px;
  }
  .img-txt-2-col {
    margin: 80px 0;
  }
  p:nth-child(odd) {
    margin-bottom: 0;
  }
  .right-body {
    margin-bottom: 2rem;
    h4 {
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
  }
  .third {
    font-size: 22px;
    width: 70%;
  }
  .bullet-container a {
    color: ${colors.primaryGray};
  }
  .intro-copy {
    width: auto;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .bullet-container ul {
      width: 33%;
    }
    h1 {
      margin-bottom: 80px;
    }
    .img-txt-2-col {
      display: flex;
      margin: 100px 0;
      .image-left {
        width: 66%;
        padding-right: 8px;
      }
      .right {
        width: 33%;
      }
    }
  }
`;

const StyledEvents = styled.div`
  .event-block:nth-child(1) .event-row {
    border-color: black;
  }
  .event-block {
    @media screen and (min-width: 768px) {
      margin: 0 auto;
      max-width: 35rem;
    }
    @media screen and (min-width: 1025px) {
      max-width: 100%;
    }
  }
`;

const StyledTitle = styled.h2`
  color: black;
  font-size: 40px;
  font-weight: 700;
  margin: 3em auto 1em;
  text-align: center;
  width: 100%;
`;

const StyledEventBlock = styled.div`
  padding-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
  padding: 0 0 2rem;
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 1rem;
    grid-template-columns: .15fr 1fr .15fr;
    grid-template-rows: auto;
    grid-column-gap: 2rem;
  }
`;
const StyledEventRow = styled.div`
  border: solid 2px #F2F2F2;
  border-radius: 25px;
  background: ${colors.white};
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  .event-title {
    font-size: 22px;
    font-family: 'daytona-bold';
    color: black;
    :hover {
      text-decoration: underline;
    }
  }
  a {
    text-transform: capitalize;
    text-decoration: none;
    color: black;
  }
  .date {
    margin-bottom: 0.8em;
  }
  @media (min-width: ${breakpoints.tabletMax}) {
    flex-direction: row;
    grid-column: 2 / 3;
    padding: 1rem;
  }
`;

const StyledEventDetails = styled.div`
  padding: 1rem;
  @media (min-width: ${breakpoints.tabletMax}) {
    flex: 1 1 60%;
    margin-top:0;
    padding: 0;
  }
`;

const StyledEventImage = styled.div`
  display: none;
  width: 100%;
  align-self: center;
  @media (min-width: ${breakpoints.tablet}) {
    display: block;
  }
  @media (min-width: ${breakpoints.desktop}) {
    flex-basis: 40%;
    padding-left: 1rem;
  }
  img {
    margin: 0;
    width: 100%;
    @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.tabletMax}) {
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
    }
  }
`;

const StyledEventHero = styled.div`
  .hero-container {
    justify-content: flex-start;
    min-height: 200px;
    padding: 1em 0;
  }
  .img-section.right {
    display: none;
    flex: 0 0 35%;
    padding-top: 1.5rem;
  }
  .row {
    margin: 0 auto;
  }
  @media (min-width: 992px) {
    .img-section.right {
      display: block;
    }
    .hero-section.left {
      flex: 1 0 62.5%;
      max-width: 62.5%;
    }
  }
`;

class TechnologyAllianceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false,
      isBrowser: typeof window !== 'undefined'
    };
  }

  displayHero(post) {
    if (
      post.acf.hero_headline === null ||
      post.acf.hero_sub_headline === null
    ) {
      return <h1>{ReactHtmlParser(post.title)}</h1>;
    } else {
      return (
        <Hero
          headline={post.acf.hero_headline}
          sub={post.acf.hero_sub_headline}
        />
      );
    }
  }

  displayHeroImage(post) {
    if (
      post.acf.verticals_intro_image === null ||
      post.acf.verticals_intro_image.source_url === null
    ) {
      return <></>;
    } else {
      return (
        <img src={post.acf.verticals_intro_image.source_url} alt="Play Video" />
      );
    }
  }

  displayEventImage(item) {
    // Display image with source and alt text if not null
    let image = item.alliance_event_image;
    let altText = !image || image.alt_text == null ? "" : image.alt_text;
    let srcURL = !image || image.source_url == null ? "" : image.source_url;
    if (image === null) {
      return false;
    } else {
      return (<StyledEventImage><a href={item.alliance_event_link} rel="noreferrer noopener" target="_blank">
        <img src={srcURL} alt={altText}/></a></StyledEventImage>);
    }
  }
  
  render() {
    let post = this.props.data.wordpressWpAlliances;

    const arrayMixed = [];
    const arrayAll = [];

    this.props.data.allWordpressWpCaseStudy.edges.forEach(function(o) {
      arrayAll.push(o);
    });

    arrayAll.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date);
    });

    arrayMixed.push(arrayAll[0].node);
    arrayMixed.push(arrayAll[1].node);
    arrayMixed.push(arrayAll[2].node);

    return (
      <SingleTechnologyPage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={DefaultSharingImage}
        />

        <NavBar link="/technology/strategic-alliances" name="Strategic Alliances" />
        <StyledEventHero className="page-container">
          <div className="row">
            <div className="hero-section left">
              {this.displayHero(post)}
            </div>
            <div className="img-section right">
              <div className="d-none d-lg-block">
                {this.displayHeroImage(post)}
              </div>
            </div>
          </div> 
          <div className="intro-copy">{ReactHtmlParser(post.acf.intro_copy)}</div>
        </StyledEventHero>

        <div>
          {post.acf.create_slider.map((item, i) => {
              return ( <DefaultSliderSolutionsDynamic
                data={item.default_slider}
                headline={item.slider_headline}
                type={post.acf.slider_type}
                index={i}
                bgcolor="black"
              />
            )
          })}
        </div>

        <div className="page-container">
          <GlobalOurClients
            headline={post.acf.client_logo_headline}
            logos={post.acf.client_logos}
            quote={ReactHtmlParser(post.acf.quote)}
          />
        </div>

        {!post.acf.alliance_event ? false : 
          <div className="page-container">
            <StyledTitle>Upcoming Events</StyledTitle>
            <StyledEvents>
              {post.acf.alliance_event.map((item, i) => {
                return (<div className="event-block">
                  <StyledEventBlock>
                    <StyledEventRow className="event-row">
                      <StyledEventDetails>
                        <p className="date">{item.alliance_event_date}</p>
                        <div>
                          <h3 className="event-title">
                            <a rel="noreferrer noopener" href={item.alliance_event_link} target="_blank">
                              {item.alliance_event_heading}
                            </a>
                        </h3>
                        <div>{ReactHtmlParser(item.alliance_event_body)}</div>
                        </div>
                        <div>
                          <ButtonGrey rel="noreferrer noopener" target="_blank"
                            textLabel={item.alliance_event_label}
                            externalLink={item.alliance_event_link}
                          />
                        </div>
                      </StyledEventDetails>
                      {this.displayEventImage(item)}
                    </StyledEventRow>
                  </StyledEventBlock>
                </div>
              )
              })}
            </StyledEvents>
          </div>
        }
        <div className="page-container">
        {this.state.isBrowser ? window.location.href.indexOf("strategic-alliances") > -1 ?
          <RelatedContentTechnologyAlliance/> : <RelatedContentTechnology/>
        : null }
          
          {this.state.isBrowser && window.location.href.indexOf("strategic-alliances/") > -1 ?
            <RelatedVideosTechnologyAlliance /> : null
          }
        </div>

        {/* <div className="page-container">
          <Related3Grid
            header="The Proof Is in the Case Studies"
            items={arrayMixed}
            readAll="See All Case Studies"
            readAllLink="/results/case-studies/"
            gridCardType="minimal"
          />
        </div> */}
        
          <ContactBanner
            headline={post.acf.contact_headline}
            cta={post.acf.contact_cta}
            url={post.acf.contact_url}
          />
      </SingleTechnologyPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpAlliances(slug: { eq: $slug }) {
      title
      type
      content
      slug
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        slider_type
        client_logo_headline
        client_logos {
          source_url
          slug
          title
        }
        alliance_event {
           alliance_event_body
           alliance_event_date
           alliance_event_heading
           alliance_event_label
           alliance_event_link
           alliance_event_image {
            source_url
            alt_text
           }
        }
        create_slider {
          default_slider {
            slider_copy
            slider_cta_text
            slider_label
            slider_cta_url
          }
          slider_headline
        }
        verticals_intro_image {
          source_url
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          type
          topic
          slug
          date(formatString: "MMMM D, YYYY")
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          title
          type
          topic
          date(formatString: "MMMM D, YYYY")
          slug
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            client
            excerpt
          }
        }
      }
    }
  }
`;

export default TechnologyAllianceTemplate;
