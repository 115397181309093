import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from './../../style-utilities/variables';
import Related3Grid from './../../components/Related/Related3Grid';

const RelatedVideosTechnologyAlliance = () => {
  return (
    <StaticQuery
      query={TOPICS_ALLIANCE_TECHQUERY}
      render={data => {

        /*
        NOTE: The topic ids below are hardcoded to check against the posts coming in from the query below.
        85 = Adobe
        164 = Salesforce
        165 = Oracle
        */

        //eliminate the dummy data
        const noDummy = edges =>
          edges.filter(edge => edge.node.slug !== 'do-not-delete');
        
          //retrieve only the results where technology is not an empty array
        const filterForTech = arr =>
          arr.filter(item => item.node.technology.length !== 0);

        const arrayMixed = [];
        const arrayAll = [];

        // >>>>>>>>>> indexOf parameters must match appropriate pages 
        const topicID =
        /* Adobe */
          window.location.href.indexOf('strategic-alliances/adobe') !== -1 ? 85 :
        /* SalesForce */
          window.location.href.indexOf('strategic-alliances/salesforce') !== -1 ? 164 :
        /* Oracle */
          window.location.href.indexOf('strategic-alliances/oracle') !== -1 ? 165 : '';

        if (data.allWordpressWpVideos) {
          data.allWordpressWpVideos.edges.forEach(function(o) {
            arrayAll.push(o);
          });
        }

        arrayAll.sort(function(a, b) {
          return new Date(b.node.date) - new Date(a.node.date);
        });

        // Pushes all existing items to arrayMixed if video topic is Adobe, SalesForce, or Oracle
        for (let i in arrayAll) {
          if (arrayAll[i].node.topic.indexOf(topicID) !== -1) {
            arrayMixed.push(arrayAll[i].node)
          }
        }

        return topicID ? <Related3Grid header="Watch These Next" items={arrayMixed} /> : null;
      }}
    />
  );
};

//Topic#: 85
//Name: Adobe
//Load all Adobe videos
const TOPICS_ALLIANCE_TECHQUERY = graphql`
  query {
    allWordpressWpVideos(filter: {topic: {in: [85, 164, 165]}}, sort: {fields: [date], order: DESC}) {
        edges {
          node {
            title
            content
            slug
            date(formatString: "MMMM D, YYYY")
            acf {
              seo_canonical
              seo_description
              seo_title
              display_date
              video
              popularity_num
              excerpt
              primary_topic
            }
            better_featured_image {
              source_url
            }
            topic
            vertical
            our_solutions
            video_type
          }
        }
      }
      allWordpressWpTopic {
        edges {
          node {
            name
            wordpress_id
          }
        }
      }
  }
`;
export default RelatedVideosTechnologyAlliance;